// Import dependencies
import React from "react";
import { navigate } from "gatsby";
import {Button, Col, Form, Row} from "antd";

// Import components
import GeneralButton from "../form/buttons/GeneralButton";
import VanIMG from "../../assets/images/van.png";
import CarIMG from "../../assets/images/car.png";
import ScooterIMG from "../../assets/images/scooter.png";

// Import styles
import "../../pages/step.css";
import "../../pages/recycle.css";
import Checkout from "../billing/checkout";
import { ParagraphText } from "../../styled-components/UILibrary";
import { Separator } from "../separators/separator";

import {getFirebase} from "../../api/firebase";
import { DEPLOY_TYPE, STRIPE_CONSTANT } from "../../constants/stripe"

/*
    Form for the Step 4 of the checkout process page
*/
class Step4Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popupOpen: false,
      vehiculeFee: this.props.isMember ? this.props.collectionInfo?.member_fee : this.props.collectionInfo?.non_member_fee,
      vehiculeName: this.props.collectionInfo?.display_name,
      buttonDisable: false,
    };
    this.isBrowser = this.isBrowser.bind(this);
  }

  isBrowser = () => typeof window !== "undefined";

  handleSubmit = (e) => {
    this.setState({buttonDisable: true});
    const { blockedDone } = this.props;


    if (blockedDone) return;
    this.setState({
      vehiculeFee: this.props.isMember ? this.props.collectionInfo?.member_fee : this.props.collectionInfo?.non_member_fee,
      vehiculeName: this.props.collectionInfo?.display_name
    }, function() {
      this.props.form.validateFields(async (err) => {
        if (!err) {
  
  
  
            const app = import("firebase/app");
            const auth = import("firebase/auth");
            const firestore = import("firebase/firestore");
            const fuctions = import("firebase/functions")
  
            Promise.all([app, auth, firestore, fuctions]).then(async ([firebase]) => {
  
                  console.log("1");
                      let emailInclude = false;
                      await getFirebase(firebase)
                        .firestore().collection("refeal_codes").get().then((querySnapshot) => {
                          querySnapshot.forEach((doc) => {
                            if (doc.data().email == this.props.email) {
                              emailInclude = true;
                            }
                          });
                      });
                      console.log("2");  
                      console.log(emailInclude);
                      if (!emailInclude) {
                        let refeal_code = "reinin-" + this.props.firstName.replace(/ /g, "").toLowerCase().substring(0, 4);
                        let uniqueRefealCode = true;
  
                        while (uniqueRefealCode) {
                          uniqueRefealCode = false;
                          refeal_code += Math.floor(Math.random() * 10000);
                          refeal_code += String.fromCharCode(97+Math.floor(Math.random() * 26));
                          refeal_code += String.fromCharCode(97+Math.floor(Math.random() * 26));
                          await getFirebase(firebase)
                            .firestore().collection("refeal_codes").get().then((querySnapshot) => {
                              querySnapshot.forEach((doc) => {
                                if (doc.data().code == refeal_code) {
                                  uniqueRefealCode = true;
                                }
                              });
                          });
                        }
  
  
                        await getFirebase(firebase)
                          .firestore().collection("refeal_codes").add({
                            code: refeal_code,
                            email: this.props.email
                        });
  
                      }
                      console.log("3");
                      this.props.recordOrderDetails();

                      console.log(this.props.isMember);
                      console.log(this.props.addonsArray);
                if (this.props.addonsArray.length == 0 && this.props.isMember) {

                  navigate("/confirmation/" + this.props.orderId.toString());
    
                } else {

                  

                  console.log("4");
                  let lineItems = this.props.addonsArray
                  .map(addon => {
                    return {
                      price: addon.priceId,
                      quantity: 1,
                    }
                  })
                  
    
                  if (!this.props.isMember) {
                    lineItems = [
                      {
                        price: STRIPE_CONSTANT[DEPLOY_TYPE]["VEHICULE_PRICES"][this.state.vehiculeName],
                        quantity: 1,
                      },
                      ...lineItems
                    ]
                  }

                  console.log({
                                        lineItems: lineItems,
                    orderId: this.props.orderId.toString(),
                    isTest: false

                  })


                  var stripeCheckoutOrder = getFirebase(firebase)
                  .functions()
                  .httpsCallable("stripeCheckoutOrder");
                  stripeCheckoutOrder({
                    lineItems: lineItems,
                    orderId: this.props.orderId.toString(),
                    isTest: false
                  })
                  .then((data) => {
                    if (this.isBrowser()) {
                      console.log("5");
                     window.location.replace(data.data.url);
                    }   
                  });
    
                }
  /*
  DISPATCH CONFIRMATION
  , {
                state: {
                  hasSteps: true,
                  title: "Booking confirmed",
                },
              }
  */
  
  

  
          });
  
  
        }
      });
    });
  };

  render() {
    const { blockedDone } = this.props;

    return (
      <Form>
        <div className="popup_container"  style={this.state.popupOpen ? {} : {display: "none"}}>
          <div className="popup small-popup" style={{paddingTop: 80}}>
          <div className="brand-help__content statistic_popup">
            <h1>Terms & Conditions</h1>
            <p>
              <span><b>Attention:</b> Any additional products that we receive which have not been itemized in your order form will be treated as free donations.<br/></span>
            <br/>
            This is a provisional quote that may be adjusted after we receive and review the order for quality and completeness. The value of any products missing from your order will be deducted from the total quote. </p>
            <div onClick={() => {if (!this.state.buttonDisable) this.handleSubmit()}} className={"close_popup" + (this.state.buttonDisable ? " close_popup_disabled" : "")}>{this.state.buttonDisable ? "Loading" : "Got it"}</div>
          </div>
          </div>
        </div>
        <div className="select-vehicle-root select-vehicle-root-selected">
            <div className="select-vehicle-img">
              <img src={this.props.collectionInfo?.display_name == "Van" ? VanIMG : this.props.collectionInfo?.display_name == "Car" ? CarIMG : ScooterIMG }/>
            </div>
            <div className="select-vehicle-desc">
                <div className="select-vehicle-desc-name">{this.props.collectionInfo?.display_name}</div>
                <div className="select-vehicle-desc-info">{this.props.collectionInfo?.description}</div>
            </div>
            <div className="select-vehicle-price">${this.props.isMember ? this.props.collectionInfo?.member_fee : this.props.collectionInfo?.non_member_fee}</div>
        </div>
        <div className="checkout_add_ons_container">
          {
            this.props.addonsArray && this.props.addonsArray.map( item => 
            <div
              className="checkout_add_ons"
            >
              <span>{item.name}</span>
              <span>{item.price}$</span>
            </div>
            )
          }            
        </div>
        <div className="check_btn_final_container" onClick={() => this.setState({popupOpen : true})} >{this.props.isMember ? "Place order" : "Pay for the collection"}</div>
      </Form>
    );
  }
}

export default Form.create({ name: "checkout-4" })(Step4Form);
